import React, { useState } from "react";
import { settings } from "./settings";
// import Split from "react-split"
import { searchToObject } from "./helpers"
import { routes } from "./routes.js";
// import "./redirects.js"

// GlobalState
import GlobalState from "./context/globalState";

// Router
import { Router, Route, Switch } from "react-router-dom";
import { matchPath } from "react-router";
import { createBrowserHistory } from "history";

// Components
import Maintenance from "./components/Maintenance";
// import Header from './components/Header';
// import Map from './components/Map';
// import Search from './components/Search';
// import Details from './components/Details';

// Styling
import "./assets/sass/App.scss";

// React Google Analytics Module
import ReactGA from "react-ga";

ReactGA.initialize(settings.googleAnalytics);

const matchSearch = matchPath(window.location.pathname, {
  path: "/search/:q",
});

let matchRecordUrl = matchPath(window.location.pathname, {
  path: "/:type(sales|people|persons|places|sources)/:id/:title",
});

if (!matchRecordUrl || !matchRecordUrl.isExact) {
  matchRecordUrl = matchPath(window.location.pathname, {
    path: "/:type(sales|people|persons|places|sources)/:urn",
  });
}

if (matchRecordUrl && matchRecordUrl.params.type === "person") {
  matchRecordUrl = { ...matchRecordUrl };
  matchRecordUrl.params.type = "people";
}
const queryStrings = searchToObject();

/**
 * Custom slider icon between map and content
 * @param {*} index
 * @param {*} direction
 */
const customGutter = (index, direction) => {
  const gutter = document.createElement("div");
  gutter.className = `gutter gutter-${direction} test`;

  const sliderIcon = document.createElement("div");
  sliderIcon.className = `slider`;
  gutter.appendChild(sliderIcon);

  const leftArrow = document.createElement("div");
  leftArrow.className = "left-arrow";
  sliderIcon.appendChild(leftArrow);

  const rightArrow = document.createElement("div");
  rightArrow.className = "right-arrow";
  sliderIcon.appendChild(rightArrow);

  return gutter;
};

function App() {
  let customHistory = createBrowserHistory();

  const [splitSizes, setSplitSizes] = useState(null);

  const onDragEnd = (sizes) => {
    // Perhaps send `sizes` to GA ?

    console.log("split sizes:");
    console.log(sizes);

    setSplitSizes(sizes);
  };

  const styles = { 
    textAlign: 'center', 
    fontSize: '2rem' 
  };

  return (
    <GlobalState>
      <Router basename={process.env.REACT_APP_PATH} history={customHistory}>
        <Maintenance />
        

        <div className="columns content-columns is-mobile">
          <div className="column" style={styles}>
            <br />
            <h1>
              The art world in Britain 1660–1735 database is currently under
              construction.
            </h1>
            <br />
            <h1>
              We will return soon.
            </h1>
          </div>
        </div>
      </Router>
    </GlobalState>
  );
}

export default App;
