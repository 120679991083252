import withTracker from './hoc/withTracker';

// Pages
import Home from './pages/Home';
import theProject from './pages/theProject';
import theDatabase from './pages/theDatabase';
import News from './pages/News';
import NewsArticle from './pages/NewsArticle';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

// Components
import Searchresults from './components/Searchresults';

export const routes = [
    {
        path: "/the-project",
        component: withTracker(theProject)
    },
    {
        path: "/the-database",
        component: withTracker(theDatabase)
    },
    {
        path: [
            "/news/:newsId/:title",
            "/news/:newsId"
        ],
        component: withTracker(NewsArticle)
    },
    {
        path: "/news",
        component: withTracker(News)
    },
    {
        path: "/contact",
        component: withTracker(Contact)
    },
    {
        path: [
            "/search/:searchString",
            "/search",
            "/sale",
            "/sales",
            "/place",
            "/places",
            "/person",
            "/people",
            "/source",
            "/sources"
        ],
        component: withTracker(Searchresults)
    },
    {
        path: [
            "/",
            "/home"
        ],
        component: withTracker(Home)
    },
    {
        component: withTracker(NotFound)
    }
];