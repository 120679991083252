import React from 'react';

export default () => {
  return (
    <div className="content">
      <article>
        <h1 className="title"><span className="text-danger">404</span> Page Not Found (.)</h1>
        <p className="lead">Sorry, that page does not exist</p>
      </article>
    </div>
  );
};
