import React, { useContext, useEffect } from 'react'
import ArtworldContext from './../context/ArtworldContext'
import NewsList from './../components/NewsList'

export default () => {

  const context = useContext(ArtworldContext);

  /**
  * Run useEffect
  */
  useEffect(() => {

    // Close details if open
    context.clearActiveRecord()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content" id="news">
      <article>
        <h1 className="title">News</h1>

        <NewsList numberOfItems={5} />

      </article>
    </div>
  );
};
