import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {
        // console.log('withTracker page:')
        // console.log(page)

        ReactGA.set({
            page,
            ...options
        });
        ReactGA.pageview(page);
    };

    const HOC = class extends Component {
        componentDidMount() {
            const {
                location: { pathname: page, search: searchparams }
            } = this.props;

            const _page = page + (searchparams ? searchparams : '')

            trackPage(_page);
        }

        // eslint-disable-next-line camelcase
        UNSAFE_componentWillReceiveProps(nextProps) {
            const {
                location: { pathname: currentPage, search: searchparams }
            } = this.props;

            const _currentPage = currentPage + (searchparams ? searchparams : '');
            const nextPage = nextProps.location.pathname + (nextProps.location.search ? nextProps.location.search : '');

            if (_currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
}