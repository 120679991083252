// import React, { useContext, useState } from 'react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { settings, pages } from './../settings'
// import Search from './Search'
import { IoMdSearch } from 'react-icons/io'
// import ArtworldContext from './../context/ArtworldContext'

export const backgroundStyles = {
  backgroundImage: "url('" + process.env.REACT_APP_URL + "assets/img/banner.jpg')"
}

const menuBtnDefaultClasses = 'menuBtn is-hidden-desktop '

const Header = (props) => {

  // const context = useContext(ArtworldContext)

  const [headerMenu, setHeaderMenu] = useState(false);

  const toggleHeader = () => {
    console.log('toggleHeader clicked')
    const newValue = headerMenu ? false : true
    setHeaderMenu(newValue)
    // context.updateGlobalState('headerMenu', newValue)
  };

  const navbarItems = pages.map(page => {
    return <Link to={page.url} key={page.url} className="navbar-item is-capitalized" onClick={() => toggleHeader()} title={page.title}>{page.name}</Link>
  })

  // const navbarItems = context.pages.map(page => page.category === 'Main Menu' ? <Link to={process.env.REACT_APP_PATH + slugify(page.title)} key={page.id} className="navbar-item is-capitalized" onClick={props.toggleHeader} title={page.title}>{page.title}</Link> : '')

  const menuBtnClasses = headerMenu ? menuBtnDefaultClasses + 'active' : menuBtnDefaultClasses

  const navClasses = headerMenu ? 'active' : ''

  return (
    <div id="header">

      <div className="bg" style={props.backgroundStyles}>
        <a className="headerLink" href={pages[0].url} title={pages[0].title} >
          {props.branding}
        </a>
      </div>

      <button className={menuBtnClasses} onClick={() => toggleHeader()}>
        <span>Menu</span>
      </button>

      <nav className={navClasses}>

        {navbarItems}

      </nav>

    </div>
  )
}

Header.propTypes = {
  branding: PropTypes.string.isRequired,
  backgroundStyles: PropTypes.object
};

Header.defaultProps = {
  branding: settings.projectName,
  backgroundStyles
};

export default Header;