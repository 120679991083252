import React from 'react'
import axios from 'axios'
import { calcOpacity, slugify, searchToObject, getCurrentPage } from './../helpers'
import ArtworldContext from './ArtworldContext'
import { settings } from './../settings'
import { isEmpty, isObjectLike, find, reverse, startsWith } from 'lodash'

const searchresultsDefaultArray = {
  results: [],
  total: 0,
  counts: { sales: 0, people: 0, places: 0, sources: 0 },
  pages: 0,
  combined_counts: {}
}

const advancedSearchDefaultArray = {
  keyword: '',
  page: getCurrentPage()
}

class GlobalState extends React.Component {
  state = {
    // App settings
    categories: {
      sales: 0,
      people: 0,
      places: 0,
      sources: 0
    },

    // Map settings
    use_map: localStorage.getItem('use_map') || 'os',
    zoom: localStorage.getItem('zoom') || 12,
    lat: localStorage.getItem('lat') || settings.mapCenter[0],
    lng: localStorage.getItem('lng') || settings.mapCenter[1],
    leafletElement: null,
    defaultSliderValue: localStorage.getItem('defaultSliderValue') || 100,
    geoData: [],

    // Search settings
    // advancedSearch: {
    //   keyword: '',
    //   page: getCurrentPage()
    // },

    advancedSearch: { ...advancedSearchDefaultArray },
    searchresults: { ...searchresultsDefaultArray },
    searching: false,
    searchTimeout: '',
    searchOptions: searchToObject(),

    // Styling settings
    mapDivStyles: 'column be-smooth',

    // Store all page content in here
    pages: [],

    // Custom browser history array
    browserHistory: []
  };

  /**
   * Update the map center position
   */
  updateMapCenter = (lat, lng) => {
    if (lat === undefined) return;
    if (lng === undefined) return;

    /**
     * Offset lat/lng by 300 pixels to make marker appear in center of screen
     */
    // if (this.state.leafletElement) {
    //   var latLngToContainerPoint = this.state.leafletElement.latLngToContainerPoint(
    //     [lat, lng]
    //   );

    //   const new_x = latLngToContainerPoint.x + 300;

    //   var new_lat_lng = this.state.leafletElement.containerPointToLatLng([
    //     new_x,
    //     latLngToContainerPoint.y
    //   ]);

    //   lat = new_lat_lng.lat;
    //   lng = new_lat_lng.lng;
    // }

    this.setState({
      lat: lat,
      lng: lng
    });

    this.saveToLocalStorage('lat', lat);
    this.saveToLocalStorage('lng', lng);
  };

  /**
   * Set map opacity
   */
  updateMapsOpacity = (e, _value) => {
    const value = parseFloat(_value);

    this.setState({
      defaultSliderValue: value
    });

    const _newOpacity = calcOpacity(value);

    this.setState({
      overlayMapsOpacity: _newOpacity
    });

    this.saveToLocalStorage('defaultSliderValue', value);
  };

  /**
   * Update the global advanced search state
   *
   * This is used to send to the API so we can filter the results
   *
   * @param {string} groupName The category name
   * @param {string} objectVariableName The category name
   * @param {object} newArray The array to add
   * @returns {null} We do not return anything
   */
  updateAdvancedSearch = (groupName, objectVariableName, newArray, callApi = true) => {
    // Get the current filters from the state
    let currentFilters = { ...this.state.advancedSearch };

    let filterName = groupName;

    if (!isEmpty(objectVariableName)) {
      filterName += '__' + objectVariableName;
    }

    currentFilters[filterName] = newArray;

    if (callApi) {
      // Save the new filters
      this.setState(
        {
          // searching: true,
          advancedSearch: currentFilters
        },
        () => this.makeApiCall()
      );
    }
  };


  /**
   * Same function as above but instead of updating a single value we update with a complete array
   * @param {object} arr 
   */
  updateAdvancedSearchWithArray = arr => {
    // Save the new filters
    this.setState(
      {
        // searching: true,
        advancedSearch: arr
      },
      () => this.makeApiCall()
    );
  }

  makeApiCall = () => {

    if (this.state.searching) return

    this.setState({
      searching: true
    });

    // Change the default maxSearchResults
    const params = { ...this.state.advancedSearch }
    params.limit = settings.maxSearchResults

    axios
      // .get(process.env.REACT_APP_API2_URL + 'search', {
      .get(process.env.REACT_APP_API2_URL + 'searchOnly', {
        params: params
      })
      .then(res => {
        let data = [];

        // console.log('res.data')
        // console.log(res.data)
        // return

        if (isObjectLike(res.data)) {
          // if (res.data.total > 0) {
          //   /**
          //    * This part is used to auto generate geo locations for places without a geo location
          //    */
          //   res.data.results.map((result, key) => {
          //     if (result.type === 'place' && isEmpty(result.geodata)) {
          //       const randomPoint = randomLocation.randomCirclePoint(
          //         {
          //           latitude: 51.510868,
          //           longitude: -0.109772
          //         },
          //         8000
          //       );

          //       const geodata = [
          //         {
          //           type: 'marker',
          //           drawingData:
          //             randomPoint.latitude + ',' + randomPoint.longitude,
          //           lat: randomPoint.latitude,
          //           lng: randomPoint.longitude
          //         }
          //       ];

          //       res.data.results[key]['geodata'] = geodata;
          //     }

          //     return '';
          //   });
          // }
          data = res.data.response;
        } else {
          console.log('There seems to be a problem with the JSON');
        }

        this.setState({
          searching: false,
          searchresults: data
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  /**
   * This will call the API and get 1 record back,
   * this record will be set as active record
   */
  getRecord = async props => {
    await axios
      .get(process.env.REACT_APP_API2_URL + 'search', {
        params: props
      })
      .then(res => {
        const record = res.data.results[0];
        const unique_key = this.createUniqueKey(record);
        this.setActiveRecord(record, unique_key, 0);
      });
  };


  getPageContent = async pageId => {
    let runFetch = true
    let pages = this.state.pages

    if (!isEmpty(pages)) {
      runFetch = false
    }

    if (runFetch) {
      return await axios
        .get(process.env.REACT_APP_API2_URL + 'pages', {
          params: {
            // pages: [1, 2, 3, 4, 5]
          }
        })
        .then(res => {

          if (!isEmpty(res.data)) {
            this.setState({
              pages: res.data.response
            })

            return res.data.response.find(x => x.id === pageId);

          }
        });
    }
    else {

      return pages.find(x => x.id === pageId);

    }

  }

  /**
   * Get a list of news items. When `newsId` is specified, `numberOfItems` and `page` don't matter. Just one record will be returned
   * @param {int} numberOfItems 
   * @param {int} page 
   * @param {int} newsId 
   */
  getNewsContent = async (numberOfItems = 5, page = 1, newsId = 0) => {
    return await axios
      .get(process.env.REACT_APP_API2_URL + 'news', {
        params: {
          numberOfItems,
          page,
          newsId
        }
      })
      .then(res => {
        if (!isEmpty(res.data)) {
          if (res.data.response.count > 0) {
            return res.data.response.results
          }
        }
      });
  }

  getFilters = async () => {
    let runFetch = true
    let advancedSearchFilters = this.state.advancedSearchFilters

    if (!isEmpty(advancedSearchFilters)) {
      runFetch = false
    }

    if (runFetch) {
      return await axios
        .get(process.env.REACT_APP_API2_URL + 'filters', {})
        .then(res => {
          if (!isEmpty(res.data)) {
            return res.data.response;
          }
        });
    }
    else {

      return advancedSearchFilters;

    }
  }

  getGeoData = async () => {
    let runFetch = true
    let geoData = this.state.geoData

    if (!isEmpty(geoData)) {
      runFetch = false
    }

    if (runFetch) {
      return await axios
        .get(process.env.REACT_APP_API2_URL + 'geodata', {})
        .then(res => {
          if (!isEmpty(res.data)) {
            return res.data.response;
          }
        });
    }
    else {

      return geoData;

    }
  }

  createUniqueKey = record => {

    if (record.type === 'sale') {
      record.type = 'sales'
    } else if (record.type === 'place') {
      record.type = 'places'
    } else if (record.type === 'person') {
      record.type = 'people'
    } else if (record.type === 'source') {
      record.type = 'sources'
    }

    return record.type + '-' + record.id;
  };

  createSlug = record => {

    if (record.type === 'sale') {
      record.type = 'sales'
    } else if (record.type === 'place') {
      record.type = 'places'
    } else if (record.type === 'person') {
      record.type = 'people'
    } else if (record.type === 'source') {
      record.type = 'sources'
    }

    return record.type && record.id && record.title ? (
      '/' + record.type + '/' + record.id + '/' + slugify(record.title)
    ) : ''
  };

  /**
   * Set active record
   */
  setActiveRecord = (record, unique_key, index) => {

    const obj = {}

    if (record) obj.activeRecord = record
    if (unique_key) obj.activeRecordUniqueKey = unique_key
    if (index) obj.activeRecordIndex = index

    // console.log('setActiveRecord with obj')
    // console.log(obj)

    this.setState(obj);
  };

  /**
   * Clear active record
   */
  clearActiveRecord = () => {
    this.setState({
      activeRecord: '',
      activeRecordUniqueKey: '',
      activeRecordIndex: ''
    });
  };


  resetSearch = () => {

    console.log('resetSearch...')
    this.clearActiveRecord()
    this.updateGlobalState('searchresults', { ...searchresultsDefaultArray })
    this.updateGlobalState('advancedSearch', { ...advancedSearchDefaultArray })
  };

  /**
   * Set active details
   */
  updateGlobalState = (name, value, debug) => {
    if (debug) {
      console.log({
        [name]: value
      });
    }
    this.setState({
      [name]: value
    });
  };

  /**
   * Save to localStorage
   */
  saveToLocalStorage = (name, value, debug) => {
    if (debug) {
      console.log({
        [name]: value
      });
    }
    localStorage.setItem([name], value);
  };

  /**
   * Update browser URL
   * @param {string} url 
   * @param {string} name 
   * @param {string} desciption 
   */
  setPushState = (url = '', name = '', desciption = '') => {
    const _url = !isEmpty(url) ? url : process.env.REACT_APP_URL;
    const _name = !isEmpty(name) ? name : 'home';
    const _desciption = !isEmpty(desciption) ? desciption : 'home';
    window.history.pushState({ name: _name }, _desciption, _url);
  };

  /**
   * Push a location to the browser history
   * @param {string} url 
   */
  setLocation = (url) => {
    this.state.browserHistory.push(url)
  };

  /**
   * Get the last history url (or search for one)
   * @param {string} urlStartsWith 
   */
  getLocation = (urlStartsWith = '') => {
    if (!isEmpty(urlStartsWith)) {
      return find(reverse(this.state.browserHistory), function (u) { return startsWith(u, urlStartsWith.toString()); })
    } else {
      return this.state.browserHistory[this.state.browserHistory.length - 1]
    }
  };


  createCurrentURL = () => {

    // console.log('queryStrings')
    // console.log(queryStrings)

    // console.log('this.state.advancedSearch')
    // console.log(this.state.advancedSearch)

    // Clone advancedSearch
    let advancedSearch = { ...this.state.advancedSearch }

    // Default options
    const page = { 'page': advancedSearch.page }
    const keyword = { 'keyword': advancedSearch.keyword }
    // const types = { 'types': advancedSearch.types }

    // Remove default options
    delete advancedSearch.page
    delete advancedSearch.keyword
    delete advancedSearch.types

    // Create URL strings
    const _page = new URLSearchParams(page).toString();
    const _keyword = new URLSearchParams(keyword).toString();
    const _advancedSearch = new URLSearchParams(advancedSearch).toString();

    // Glue all options together
    const params = _page + (_keyword !== 'keyword=' ? '&' + _keyword : '') + (_advancedSearch ? '&' + _advancedSearch : '')

    // console.log('params')
    // console.log(params)

    // Prevent search params when NOT on search page like details
    const newUrl = window.location.pathname + (startsWith(window.location.pathname, '/search') ? '?' + params : '')


    // if (startsWith(window.location.pathname, '/search')) {
    //   const newUrl = window.location.pathname + (params ? '?' + params : '')
    // } else {
    //   const newUrl = window.location.pathname + (params ? 'search/?' + params : '')
    // }


    // const newUrl = startsWith(window.location.pathname, '/search') ?
    //   window.location.pathname + (params ? '?' + params : '')
    //   :
    //   window.location.pathname + (params ? 'search/?' + params : '')



    // console.log('newUrl')
    // console.log(newUrl)

    if (window.location.pathname !== newUrl) {
      return newUrl
    } else {
      return ''
    }
  }



  render() {
    return (
      <ArtworldContext.Provider
        value={{
          ...this.state,

          // Handlers
          setActiveRecord: this.setActiveRecord,
          clearActiveRecord: this.clearActiveRecord,
          updateGlobalState: this.updateGlobalState,
          updateMapsOpacity: this.updateMapsOpacity,
          updateMapCenter: this.updateMapCenter,
          saveToLocalStorage: this.saveToLocalStorage,
          updateAdvancedSearch: this.updateAdvancedSearch,
          setPushState: this.setPushState,
          setLocation: this.setLocation,
          getLocation: this.getLocation,
          createCurrentURL: this.createCurrentURL,
          updateAdvancedSearchWithArray: this.updateAdvancedSearchWithArray,
          resetSearch: this.resetSearch,

          createUniqueKey: this.createUniqueKey,
          createSlug: this.createSlug,

          makeApiCall: this.makeApiCall,
          getRecord: this.getRecord,
          getRecord2: this.getRecord2,
          getPageContent: this.getPageContent,
          getFilters: this.getFilters,
          getNewsContent: this.getNewsContent,
          getGeoData: this.getGeoData
        }}
      >
        {this.props.children}
      </ArtworldContext.Provider>
    );
  }
}

export default GlobalState;
