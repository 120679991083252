import React from 'react'
import dompurify from 'dompurify'
import he from 'he'

export default (props) => {

    const sanitizer = dompurify.sanitize;

    const createMarkup = function () {

        // Allow target="_blank"
        const text = sanitizer(props.text, { ADD_ATTR: ['target'] })

        // Use `he` to decode HTML
        return <div dangerouslySetInnerHTML={{ __html: he.decode(text) }} />
    };

    return createMarkup()
};