import React from 'react';
import { settings, sponsorsArray } from './../settings';
import { slugify } from './../helpers'

const Sponsors = () => {
    return (
        <div className="sponsors">

            {sponsorsArray.map((sponsor) => {

                const classes = 'sponsor sponsor-' + slugify(sponsor.name)

                return (
                    <div className={classes} key={sponsor.name}>
                        <a href={sponsor.url} target="_blank" rel="noopener noreferrer" title={`Sponsor: ${sponsor.name}`}>
                            <img src={`${settings.projectUrl}assets/img/sponsors/${sponsor.logo}`} alt={`Sponsor: ${sponsor.name}`} />
                        </a>
                    </div>
                )
            })}

        </div>
    );
};

export default Sponsors;
