import React, { useContext, useState, useEffect } from 'react';
import ArtworldContext from './../context/ArtworldContext';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import SearchresultsButton from './SearchresultsButton';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import { recordClickHandler } from './../clickHandlers'
import { isEmpty } from 'lodash'

export const nothingFoundText =
  'Sorry we did not find any results for this query.';
export const nothingFoundImage = (
  <img
    src="/assets/img/nothing-found.png"
    alt="Sorry we did not find any results for this query."
  />
);

const override = css`
  display: block;
  margin: 0 auto;
`;

const Searchresults = (props) => {

  const context = useContext(ArtworldContext);
  let history = useHistory();

  const [buttons, setButtons] = useState('');
  const [searchresults, setSearchresults] = useState('');
  const [fromPreviousPage, setFromPreviousPage] = useState(false);
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {

    if (context.advancedSearch.page !== activePage) {
      setActivePage(context.advancedSearch.page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.advancedSearch])

  const keyboardNavigation = event => {

    if (event.key === 'ArrowRight') {
      const nextIndex = context.activeRecordIndex + 1

      if (!isEmpty(buttons) && nextIndex !== context.activeRecordIndex && nextIndex <= context.searchresults.total) {

        if (nextIndex > (context.searchresults.results.length - 1)) {
          const nextPage = context.advancedSearch.page + 1

          // trigger next page (if exists)
          if (nextPage <= context.searchresults.pages) {
            // Update page
            context.updateAdvancedSearch('page', '', nextPage);
          }
        }
        else {
          const nextRecord = buttons[nextIndex].props.children[0].props
          onClickHandler(nextRecord.record, nextRecord.unique_key, nextRecord.index)
        }
      }
    }

    if (event.key === 'ArrowLeft') {
      const prevIndex = context.activeRecordIndex - 1

      if (!isEmpty(buttons) && prevIndex !== context.activeRecordIndex) {

        if (prevIndex === -1) {
          const prevPage = context.advancedSearch.page - 1

          // trigger previous page (if exists)
          if (prevPage >= 1) {

            // Set to true so the correct record will be selected
            setFromPreviousPage(true)

            // Update page
            context.updateAdvancedSearch('page', '', prevPage);
          }
        }
        else {
          const prevRecord = buttons[prevIndex].props.children[0].props
          onClickHandler(prevRecord.record, prevRecord.unique_key, prevRecord.index)
        }

      }
    }
  }

  const onClickHandler = (record, unique_key, index) => {

    if (context.activeRecord && context.activeRecord.id === record.id) {
      context.clearActiveRecord();
    } else {

      recordClickHandler({
        record: record,
        index: index,
        unique_key: unique_key,
        context: context,
        history: history,
        from: 'searchresults'
      })

    }
  };


  useEffect(() => {
    window.addEventListener("keydown", keyboardNavigation);
    return () => window.removeEventListener("keydown", keyboardNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, buttons]);


  useEffect(() => {
    if (!isEmpty(context.searchresults.results)) {
      const buttons = context.searchresults.results.map((record, index) => {
        const unique_key = context.createUniqueKey(record);
        return !isEmpty(record.title) ? (
          <div className="column is-full" key={unique_key}>
            <SearchresultsButton
              key={record.id}
              record={record}
              unique_key={unique_key}
              index={index}
              onClickHandler={onClickHandler}
            />{' '}
          </div>
        ) : null;
      })

      setButtons(buttons)
    } else {
      setButtons('')
      setSearchresults(nothingFound)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.searchresults.results])


  useEffect(() => {
    if (!isEmpty(buttons) && !context.searching) {
      setSearchresults(!isEmpty(buttons) ? buttons : nothingFound)

      // This will activate the last result, WHEN details page is already open and the first record of the previous page was selected
      if (context.activeRecordIndex === 0 && fromPreviousPage) {
        const lastIndex = context.searchresults.results.length - 1
        const nextRecord = buttons[lastIndex].props.children[0].props
        onClickHandler(nextRecord.record, nextRecord.unique_key, nextRecord.index)
        setFromPreviousPage(false)
      }

      // This will activate the first result, WHEN details page is already open and the last record of the previous page was selected
      if (context.activeRecordIndex === 4) {
        const nextRecord = buttons[0].props.children[0].props
        onClickHandler(nextRecord.record, nextRecord.unique_key, nextRecord.index)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons])

  const nothingFound = (
    <div className="column is-full has-text-centered nothing-found">
      {' '}
      {nothingFoundText} <br /> {nothingFoundImage}{' '}
    </div>
  );

  const loadingSpinner = (
    <div className="sweet-loading">
      <div className="inner">
        <ClipLoader
          css={override}
          sizeUnit={'px'}
          size={30}
          color={'#3298dc'}
          loading={true}
        />
        Loading search results
      </div>
    </div>
  );

  // Handle pagination click
  const handlePaginationClick = (data) => {
    // New page number
    const page = data.selected + 1

    // const currentPage = context.advancedSearch.page

    if (parseInt(context.advancedSearch.page) !== parseInt(page)) {

      // Update the global context
      context.updateAdvancedSearch('page', '', page);

      // Create search result URL
      // const url = window.location.pathname + '?page=' + page

      // Update URL to search
      // history.push(url)
    }
  }

  return (
    <div className="results">
      {context.searching ? loadingSpinner : ''}
      {searchresults}

      <ReactPaginate
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={context.searchresults.pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePaginationClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        pageLinkClassName={'button is-small'}
        activeLinkClassName={'button is-small is-info'}
        initialPage={activePage - 1}
        forcePage={activePage - 1}
      />

    </div>
  );
};

Searchresults.propTypes = {
  nothingFoundText: PropTypes.string
};

Searchresults.defaultProps = {
  nothingFoundText
};

export default Searchresults;
