var _ = require('lodash');

/**
 * Remove an item from the array
 * 
 * @param {object} arr The array
 * @param {string} value The value to remove
 * @returns {object} We return the new array
 */
export function removeFromArray(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

/**
 * Calculate the opacity
 */
export function calcOpacity(value) {
    return parseFloat((value ? value : 1) / 100).toFixed(2)
}

/**
 * Return an array containing characters A to Z
 */
export function aToZ() {
    let AtoZ = []
    for (var i = 65; i <= 90; i++) {
        AtoZ.push(String.fromCharCode(i))
    }
    return AtoZ
}


/**
 * This function handles the checkboxes click events.
 * On change the state of the component is updated as well as the globel state.
 * 
 * @param {object} e The handlers event
 * @param {object} componentState The component state (this)
 * @param {string} groupName A string to which the selected items are saved to
 * @param {string} objectVariableName The object name for the selected items
 * @returns {null} We do not return anything
 */
export function checkboxHandler(e, componentState, groupName, objectVariableName) {
    // const category_id = e.target.id;
    const checkbox_value = e.target.value;

    let currentActiveItems = componentState.state.activeItems

    if (e.target.checked) {
        currentActiveItems.push(checkbox_value)
    }
    else {
        currentActiveItems = removeFromArray(currentActiveItems, checkbox_value)
    }

    componentState.setState({
        activeItems: currentActiveItems
    })

    /**
     * Update the global context
     */
    componentState.context.updateAdvancedSearch(
        groupName,
        objectVariableName,
        currentActiveItems
    )

    return null
}

/**
 * This function handles the checkboxes click events.
 * On change the state of the component is updated as well as the globel state.
 * 
 * @param {object} e 
 * @param {*} activeItems 
 * @param {*} callbackFunction 
 * @param {*} context 
 * @param {*} groupName 
 * @param {*} objectVariableName 
 */
export function checkboxHandlerStateless(e, activeItems, callbackFunction, context, groupName, objectVariableName) {
    const checkbox_value = e.target.value;

    let currentActiveItems = activeItems

    if (e.target.checked) {
        currentActiveItems.push(checkbox_value)
    }
    else {
        currentActiveItems = removeFromArray(currentActiveItems, checkbox_value)
    }

    callbackFunction(currentActiveItems)

    /**
     * Update the global context
     */
    context.updateAdvancedSearch(
        groupName,
        objectVariableName,
        currentActiveItems
    )

    return null
}


/**
 * Create a URL friendly string
 * @param {string} string The input string
 */
export function slugify(string, spacer = '-') {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, spacer) // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, spacer) // Replace multiple - with single -
        .replace(/-+$/, '') // Trim - from end of text
}

/**
 * Transform query strings to object
 */
export function searchToObject() {
    return window.location.search.substring(1).split("&").reduce(function (result, value) {
        const parts = value.split('=');

        let field = decodeURIComponent(parts[0])
        // field = field.includes("__") ? field.split("__") : field

        // console.log(field)

        let values = decodeURIComponent(parts[1])
        values = values.includes(",") ? values.split(",") : values

        if (parts[0]) result[field] = values;
        return result;
    }, {})
}

/**
 * Only return the page number from the query strings
 */
export function getCurrentPage() {
    const searchOptions = searchToObject()
    return searchOptions.page ? searchOptions.page : 1
}

/**
 * Search for `optionName` in the search options array
 * @param {string} optionName 
 */
export function findSearchOption(optionName) {
    const searchOptions = searchToObject()
    return searchOptions[optionName] ? searchOptions[optionName] : null
}


/**
 * The following set of functions and prototypes are used to get `area` and `centroid` from `polygons`
 */
export function Point(x, y) {
    this.x = x;
    this.y = y;
}

export function Region(points) {
    this.points = points || [];
    this.length = points.length;
}

Region.prototype.area = function () {
    var area = 0,
        i,
        j,
        point1,
        point2;

    for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
        point1 = this.points[i];
        point2 = this.points[j];
        area += point1[0] * point2[1];
        area -= point1[1] * point2[0];
    }
    area /= 2;

    return area;
};

Region.prototype.centroid = function () {
    var x = 0,
        y = 0,
        i,
        j,
        f,
        point1,
        point2;

    for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
        point1 = this.points[i];
        point2 = this.points[j];

        f = point1[0] * point2[1] - point2[0] * point1[1];
        x += (parseFloat(point1[0]) + parseFloat(point2[0])) * f;
        y += (parseFloat(point1[1]) + parseFloat(point2[1])) * f;
    }

    f = this.area() * 6;

    return new Point(x / f, y / f);
};


/**
 * Return the position array
 * @param {object} result 
 */
export function GetPosition(result) {
    if (result && result.drawingData) {
        return result.drawingData.toString().replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').split(',')
    } else {
        return false
    }
}

/**
 * Polyline & Polygon - create special array of all points
 */
export function PolyPoints(position) {
    let polyPoints = []

    for (let i = 0; i < (position.length / 2); i++) {
        const first = i * 2
        const last = first + 1

        polyPoints.push([position[first], position[last]])
    }

    return polyPoints
};

/**
 * Return center of a marker
 * @param {object} result 
 */
export function GetCenter(result) {
    let center = ''

    if (!_.isEmpty(result.geodata)) {
        const position = GetPosition(result)

        if (result.geodata[0].type === 'polyline' || result.geodata[0].type === 'polygon') {
            const points = PolyPoints(position)
            const region = new Region(points)
            center = region.centroid()
        } else {
            center = { x: position[0], y: position[1] } // test
        }
    }

    return center
}