import React, { useContext, useState, useEffect } from 'react'
import ArtworldContext from './../context/ArtworldContext'
import HTMLText from './../components/HTMLText'
import NewsList from './../components/NewsList'
import { Link } from 'react-router-dom'
import { settings } from './../settings'
import Sponsors from './../components/Sponsors'

export default () => {

  const context = useContext(ArtworldContext);

  const [homeText, setHomeText] = useState('Loading..');

  /**
  * Fetch home text
  */
  async function fetchHomeText() {
    return await context.getPageContent(1)
      .then(res => {
        setHomeText(res.content)
        return res
      })
      .catch(err => {
        console.warn(err)
        setHomeText(settings.defaultApiErrorText)
      });
  }

  /**
  * Run useEffect
  */
  useEffect(() => {
    fetchHomeText();

    // ReactGA.pageview(window.location.pathname + window.location.search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const latestNews = (
    <div id="news">

      <NewsList numberOfItems={2} showPagination="false" preText={<h3>Latest news</h3>} postText={<Link className="button is-small" to={{ pathname: "/news/" }}>More news</Link>} />

    </div>
  )

  return (
    <div id="home" className="content">

      <article>

        <HTMLText text={homeText} />

        <hr />

        {latestNews}

      </article>


      <div className="bottonPart is-hidden-desktop">
        <Sponsors />
      </div>

    </div>
  );
};