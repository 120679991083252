export const settings = {
  projectName: 'The art world in Britain 1660 to 1735',
  projectUrl: process.env.REACT_APP_URL,
  apiKey: 'AQQQ8a3Mz4i2Xbb7jBz2RYXpBZd1rviM3EWC1SnV1Sfi*&',
  keyword: '',
  debug: false,
  searchParams: '',
  googleMapApiKey: 'AIzaSyAaLphjVZNGYPAd1hFlPv6D1LimzLaEzx8',
  showAdditionalPages: 1,
  googleAnalytics: 'UA-21938253-21',
  mapCenter: [51.5116265, -0.1239709], // <-- St Paul’s Church Covent Garden
  mapDefaultZoom: 12,
  maxSearchResults: 100,
  defaultApiErrorText: 'There seems to be a problem connecting to our API. Please try again later.'
};

export const pages = [
  {
    name: 'Home',
    url: process.env.REACT_APP_PATH,
    title: 'Navigate to the homepage'
  },
  {
    name: 'News',
    url: process.env.REACT_APP_PATH + 'news',
    title: 'Navigate to the news overview page'
  },
  {
    name: 'The project',
    url: process.env.REACT_APP_PATH + 'the-project',
    title: 'More information about the art world project'
  },
  {
    name: 'The database',
    url: process.env.REACT_APP_PATH + 'the-database',
    title: 'More information about the art world database'
  },
  {
    name: 'Contact',
    url: process.env.REACT_APP_PATH + 'contact',
    title: 'Get in contact with us through our contact form'
  }
]

export const overlayMaps = [
  {
    name: 'Morgan 1682',
    desc: 'London &c. actually survey\'d by Wm. Morgan [...] begun by Mr. Ogilby and finished [...] by Wm. Morgan, 1681–82. British Library, Maps 175.t.1.(1)',
    opacity: 100,
    folderName: 'MORGAN_1682',
    minZoom: '10',
    maxZoom: '19'
  },
  {
    name: 'Rocque 1746',
    desc: 'A Plan of the Cities of London and Westminster, and Borough of Southwark; with the contiguous buildings; from an actual survey, taken by John Rocque, Land-Surveyor, and engraved by John Pine, 1746. British Library, Maps * 3480.(293)',
    opacity: 0,
    folderName: 'ROCQUE_1746',
    minZoom: '10',
    maxZoom: '19'
  },
  {
    name: 'Willdey 1715',
    desc: 'Map of Great Britain and Ireland corrected from the newest & most exact observations [...] made & sold by George Willdey at ye corner House in Ludgate street next St. Pauls, 1715. British Library, Maps C.11.a.2',
    opacity: 100,
    folderName: 'UK_map_1715',
    minZoom: '4',
    maxZoom: '11'
  }
]

export const sponsorsArray = [
  {
    name: 'University of York',
    logo: 'UniversityOfYork.png',
    url: 'https://www.york.ac.uk'
  },
  {
    name: 'Paul Mellon Centre for Studies in British Art',
    logo: 'Paul-Mellon-Centre-for-Studies-in-British-Art.png',
    url: 'https://www.paul-mellon-centre.ac.uk'
  },
  {
    name: 'The British Library',
    logo: 'The-British-Library.jpg',
    url: 'https://www.bl.uk/'
  }
]