import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { settings } from './../settings'
import { includes } from 'lodash'

// useHistory hook for stateless components
// import { useHistory } from 'react-router-dom';

// Context
import ArtworldContext from './../context/ArtworldContext';

export const buttonActiveClass = 'result is-size-7 button is-info';
export const buttonInActiveClass = 'result is-size-7 button is-link is-light';

const SearchresultsButton = props => {
  // let history = useHistory();

  const context = useContext(ArtworldContext);

  const [classes, setClasses] = useState(buttonInActiveClass);

  useEffect(() => {

    if (context.activeRecordUniqueKey !== undefined && context.activeRecordUniqueKey === props.unique_key) {

      /**
       * When coming directly to a record the `activeRecordIndex` is always set to 0 (zero)
       * Here we change that index to the correct one based on the selected button.
       * This is needed for the `next` and `previous` arrow keys navigation.
       */
      // if (context.activeRecordUniqueKey === 0) {
      context.updateGlobalState('activeRecordIndex', props.index)
      // }

      setClasses(buttonActiveClass)
    }
    else {
      setClasses(buttonInActiveClass)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.activeRecordUniqueKey])


  const highlightText = inputText => {

    if (context.advancedSearch.keyword && context.advancedSearch.keyword !== 'undefined') {

      const highlight = context.advancedSearch.keyword.trim()

      const text = inputText.replace(/<\/?[^>]+(>|$)/g, ' ')

      let startText = ''
      let highlightedText = ''
      let endText = ''

      if (includes(text.toLowerCase(), highlight.toLowerCase())) {

        const index = text.toLowerCase().indexOf(highlight.toLowerCase())

        startText = index !== 0 ? text.substring(0, index) : ''

        highlightedText = text.substring(index, index + highlight.length)

        endText = text.substring(index + highlight.length, text.length)

      } else {
        startText = text
      }

      return (
        <>
          {startText}
          <span className="highlight">{highlightedText}</span>
          {endText}
        </>
      )
    } else {
      return inputText
    }
  }

  const urn = process.env.REACT_APP_ENV === 'localhost' ? <span className="tag is-primary">{props.record.urn}</span> : ''

  const id = process.env.REACT_APP_ENV === 'localhost' ? <span className="tag is-primary">{props.record.id}</span> : ''

  const title = highlightText(props.record.title)

  const showText2 = props.record.type === 'sources' && props.record.text2 ? <div className="highlightText">{highlightText(props.record.text2)}</div> : ''

  const isFullText = props.record.type === 'sources' && props.record.text2 ? <img src={`${settings.projectUrl}assets/img/book-icon.png`} className="full-text-source" alt="This is a full-text source record" /> : ''

  const type = props.record.type === 'places' ? parseInt(props.record.urn.charAt(0)) === 3 ? 'places' : 'collections' : props.record.type

  return (
    <button className={classes} key={props.record.id} onClick={() => { props.onClickHandler(props.record, props.unique_key, props.index) }}>
      <span className="tag is-warning">{type}</span>
      {urn}
      {id}
      {title}
      {isFullText}
      {showText2}
    </button>
  );
};

SearchresultsButton.propTypes = {
  buttonActiveClass: PropTypes.string,
  buttonInActiveClass: PropTypes.string
};

SearchresultsButton.defaultProps = {
  buttonActiveClass,
  buttonInActiveClass
};

export default SearchresultsButton;
