import React, { useContext, useState, useEffect } from 'react'
import ArtworldContext from '../context/ArtworldContext'
import HTMLText from '../components/HTMLText'
import { settings } from '../settings'

export default () => {

  const context = useContext(ArtworldContext);

  const [text, setText] = useState('');

  /**
  * Fetch home text
  */
  async function fetchText() {
    return await context.getPageContent(3)
      .then(res => {
        setText(res.content)
        return res
      })
      .catch(err => {
        setText(settings.defaultApiErrorText)
        console.warn(err)
      });
  }

  /**
  * Run useEffect
  */
  useEffect(() => {
    fetchText();

    // Close details if open
    context.resetSearch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <article>
        <h1 className="title">The project</h1>
        <p className="lead">
          <HTMLText text={text} />
        </p>
      </article>
    </div>
  );
};
