/**
 * MOVE THIS TO globalState.js
 */

import { GetCenter } from './helpers'

var _ = require('lodash');

export function recordClickHandler(props) {
    console.log('recordClickHandler clicked');

    if (props.context && props.record) {

        if (props.context.activeRecord !== props.record) {

            // console.log('setActiveRecord')

            const unique_key = props.unique_key ? props.unique_key : props.context.createUniqueKey(props.record);

            const index = props.index ? props.index : 0


            // console.log('props')
            // console.log(props)

            // console.log('props.context.activeRecord')
            // console.log(props.context.activeRecord)

            // console.log('props.record')
            // console.log(props.record)



            // if (props.context.activeRecord.id === props.record.id) {
            //     console.log('jaaaa')
            //     // console.log(props)
            // }



            props.context.setActiveRecord(props.record, unique_key, index);

            // Center the map
            const center = GetCenter(props.record)
            if (!_.isEmpty(center) && center.x && center.y) {
                props.context.updateMapCenter(
                    center.x,
                    center.y
                );
            }


            // Create search result URL
            const url = props.context.createSlug(props.record)

            if (props.history && url !== window.location.pathname) {
                // Update URL to search
                props.history.push(url)

                // Push URL to custom browser history array
                props.context.setLocation(url)
            }
        }
    }
}

export default function clickHandlers() {
    return true
}