import React, { useContext, useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom"
import HTMLText from './../components/HTMLText'
import axios from 'axios'
import ArtworldContext from './../context/ArtworldContext'

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export default () => {

    const { newsId } = useParams()

    const context = useContext(ArtworldContext)

    const [content, setContent] = useState('')
    const [newArticle, setNewArticle] = useState('')

    useEffect(() => {

        axios
            .get(`${process.env.REACT_APP_API2_URL}news/${newsId}`)
            .then(res => {
                if (res.data.response) {
                    setNewArticle(res.data.response)
                }
            });

        // Close details if open
        context.clearActiveRecord()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {

        if (newArticle) {

            const date = new Date(newArticle.date)
            const printDate = date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear()

            const newTitle = newArticle.title ? <h1 className="title">{newArticle.title}</h1> : ''
            const newDate = printDate ? <h5>{printDate}</h5> : ''
            const newContent = newArticle.content ? <div className="articleContent"><HTMLText text={newArticle.content} /></div> : ''

            const output = (
                <>
                    {newTitle}
                    {newDate}
                    {newContent}
                    <Link className="button is-small" to="/news">&lt;&lt; Back to overview</Link>
                </>
            )

            setContent(output)
        }

    }, [newArticle]);

    return (
        <div className="content" id="newsArticle">
            <article>
                {content}
            </article>
        </div>
    );
};
