import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
// import { useHistory } from 'react-router-dom'
import ArtworldContext from './../context/ArtworldContext'
import ReactPaginate from 'react-paginate'
import { slugify } from '../helpers'
import HTMLText from './../components/HTMLText'

var uniqid = require('uniqid');

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export default (props) => {

    const context = useContext(ArtworldContext);

    // let history = useHistory();

    const [showNewsOverview, setNewsOverview] = useState('');
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [activeNewsPage, setActiveNewPage] = useState(context.searchOptions.page ? context.searchOptions.page : 1);
    const showPagination = props.showPagination ? props.showPagination === 'false' ? false : true : true

    // Handle pagination click
    const handlePaginationClick = (data) => {
        // New page number
        const page = data.selected + 1

        // Save to state so API is being called
        setActiveNewPage(page)

        // Create search result URL
        // const url = window.location.pathname + '?page=' + page

        // Update URL to search
        // history.push(url)
    }

    const pagination = showPagination && numberOfPages > 1 ? <ReactPaginate
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={numberOfPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePaginationClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        pageLinkClassName={'button is-small'}
        activeLinkClassName={'button is-small is-info'}
        initialPage={activeNewsPage - 1}
    /> : ''


    /**
    * Fetch news
    */
    async function fetchNews() {
        return await context.getNewsContent(props.numberOfItems, activeNewsPage)
            .then(news => {

                if (props.numberOfItems > 0) {
                    news = news.slice(0, props.numberOfItems);
                }

                const output = news && news.map(newsItem => {

                    var date = new Date(newsItem.date * 1000);

                    const displayDate = date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear()

                    return (
                        <li key={uniqid()}>
                            <h4>{newsItem.title}</h4>
                            <h5>{displayDate}</h5>

                            <HTMLText text={newsItem.intro} />

                            <br />

                            <Link className="button is-small" to={{
                                pathname: "/news/" + newsItem.id + "/" + slugify(newsItem.title)
                            }}>Continue reading</Link>

                        </li >
                    )
                })

                setNewsOverview(output)

                setNumberOfPages(news.totalPages)

                return output
            })
            .catch(err => console.warn(err));
    }

    useEffect(() => {
        fetchNews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNewsPage]);


    return showNewsOverview ? (
        <>

            {props.preText ? props.preText : ''}

            <ul>
                {showNewsOverview}
            </ul>

            {pagination}

            {props.postText ? props.postText : ''}

        </>
    ) : ''
};
